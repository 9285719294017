import * as React from 'react';
import { useContext, useState, useEffect } from 'react';

import noteContext from './NoteContext';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from "react-router-dom";
import API from "./API";
import { Link } from "react-router-dom";

import LaunchIcon from '@mui/icons-material/Launch';

import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
export default function MarkComplete() {


    const anchor = 'right';

    const [homeworks, setHW] = useState([]);
    const [Stuentsv2, setStuentsv2] = useState([]);


    const { school_class } = useParams()


    const data = useContext(noteContext);

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };



    function display_student(item, student) {



        console.log(item);




        return (
            <>
                <table className='table table-sm table-striped table-bordered table-hover'>


                    <thead>

                        <tr>
                            <th>Subject</th>
                            <th>HomeWork</th>
                            <th>HomeWork start</th>
                            <th>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>



                        {



                            item[student].map((item1, index) => (


                                <tr key={index}>

                                    <td>
                                        {item1.subject}





                                        <Link target="_blank" to={`/homework/${item1.sr}`}><LaunchIcon /> </Link>
                                    </td>

                                    <td>
                                        &nbsp;

                                        {item1.homework != undefined &&


                                            item1.homework.homework


                                        }

                                    </td>
                                    <td>
                                        &nbsp;
                                        {item1.homework != undefined &&


                                            item1.homework.created_at


                                        }

                                    </td>
                                    <td>

                                        {item1.homework != undefined &&

                                            <>





                                                {item1.homework.status}

                                                <Button onClick={() => deletehomework(item1.homework)} variant="contained"> <DeleteForeverIcon /></Button>

                                            </>

                                        }



                                        {(item1.homework != undefined && item1.homework.status != "Completed") &&

                                            <>


                                                <Button onClick={() => mark_completed(item1.homework)} variant="contained"> <DoneAllIcon />    </Button>


                                            </>
                                        }












                                    </td>

                                </tr>




                            ))



                        }

                    </tbody>
                </table>


            </>

        );


    }





    let token = data.token;




    const fetchHomeWorks = async (school_class) => {


        try {
            const res = await API.get("/hw/hw_list_v2/" + school_class, {
                headers: {
                    'content-type': 'text/json', Authorization: 'Bearer ' + token
                }
            });
            setStuentsv2(res.data);



        }
        catch (error) {

            console.log(error);


        }


    };

    const deletehomework = async (item) => {



        const res = await API.post("delete_homework ", item, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });
        // setHomeworkHistory(res.data);

    }




    const mark_completed = async (item) => {

        item.status = "Completed";
        item.remark = ".";

        const res = await API.post("homework_mark_complete", item, {
            headers: {
                'content-type': 'text/json', Authorization: 'Bearer ' + token
            }
        });
        fetchHomeWorks(school_class);

    }



    function display_studentv2(student_home) {






        return (
            <>





                <table className='table table-sm table-striped table-bordered table-hover'>


                    <thead>

                        <tr>
                            <th>.</th>

                            <th>HomeWork</th>
                            <th>HomeWork start</th>
                            <th>
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>



                        {student_home.home_works.map((item1, index) => (


                            <tr key={index}>




                                <td>
                                    {item1.id}


                                </td>


                                <td>
                                    {item1.homework}
                                </td>

                                <td>
                                    {item1.created_at}
                                </td>

                                <td>
                                    {(item1.status != "Completed") &&

                                        <>


                                            <Button onClick={() => mark_completed(item1)} variant="contained"> <DoneAllIcon />    </Button>


                                        </>
                                    } </td>
                            </tr>
                        ))
                        }

                    </tbody>
                </table>


            </>

        )
    }




    useEffect(() => {

        fetchHomeWorks(school_class);



    }, []);






    return (

        <>  Homework
            {

                Object.keys(Stuentsv2).map((key, i) => (



                    <div key={i}>

                        <span>  <h5>   <Link target="_blank" to={`/homework/${Stuentsv2[key].sr}`}>
                            {Stuentsv2[key].sr}      {Stuentsv2[key].name}  <LaunchIcon />   </Link>
                        </h5></span>

                        {display_studentv2(Stuentsv2[key])}




                    </div>


                ))
            }

        </>
    )

}